import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>Contact me</h1>
    <p>Find me here:</p>
    <ul>
        <li>Github: <a href="https://github.com/joostfaassen" target="_blank" rel="noreferrer">github.com/joostfaassen</a></li>
        <li>LinkedIn: <a href="https://linkedin.com/in/joostfaassen" target="_blank" rel="noreferrer">linkedin.com/in/joostfaassen</a></li>
    </ul>
  </Layout>
)

export default IndexPage
